import { MDXProvider } from "@mdx-js/react"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import * as React from "react"
import CollapsibleSection from "../../components/CollapsibleSection"
import Layout from "../../components/Layout"

const ChageLogPage = ({ data }) => {
  const nodes = data?.changeLog?.nodes
  console.log("ChageLogPage nodes: ", nodes)
  return (
    <Layout>
      <MDXProvider>
        {nodes?.map(list => (
          <div
            className="navigations change-log"
            style={{
              minHeight: "0px",
            }}
          >
            <CollapsibleSection
              title={list?.childMdx?.frontmatter?.title}
              titleClass="menu-heading"
            >
              <MDXRenderer>{list?.childMdx?.body}</MDXRenderer>
            </CollapsibleSection>
          </div>
        ))}
      </MDXProvider>
    </Layout>
  )
}
export default ChageLogPage
export const query = graphql`
  query changeLog {
    changeLog: allFile(filter: { sourceInstanceName: { eq: "changeLog" } }) {
      nodes {
        childMdx {
          frontmatter {
            title
            date
          }
          slug
          body
        }
      }
    }
  }
`
